header.page-header { 
  background-color: black;
  position: relative; 
  overflow: hidden;
  height: 100vh;
  
  /* avoid jumping when browser address bar disappears */
  /* transition: height 999999s; */

  // works with setHeaderHeightDynamicaly() in main.js:
  min-height: calc(var(--vh, 1vh) * 100); 

  // TEMPORARLY
  // background-image: url(../assets/images/Video_Placeholder.jpg);
  // background-size: cover;
  // background-position: center;
  
  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */ 
    min-height: -webkit-fill-available;
  }
}

.logo-background {
  pointer-events: none;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  opacity: 0;
  height: 150px;
  position: fixed;
  width: 100%;
  z-index: 9999;
  transition: opacity 0.3s;
  @media (max-width: $bp-tablet) {
    height: 110px;
  }
  &.visible {
    opacity: 0.35;
  }
}

.logo--menu-button {
  // position: relative;
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  transition: opacity 0.3s, width 0.3s;
  // background-color: white;
  z-index: 999999;
  margin: 0px auto;
  cursor: pointer;
  /*background-image: url(../assets/images/Logo_CHRW_white.svg);
  background-size: contain;
  background-position: top;*/
  /*height: auto;*/

  animation-name: fade-in;
  animation-duration: .3s;
  animation-fill-mode: forwards;

  .has-opened-menu & {
    // animation: fade-out-zoom-out .4s ease-in-out;
    // animation-fill-mode: forwards;
   
    display: none;
  }

  @media (max-width: $bp-desktop) {
    width: 65px;
  }
}

.logo--sticky {
  position: fixed;
  width: 65px;
  top: 10px;
}


.headline--header {
  width: 100%;
  text-align: center;
  text-shadow: 3px 3px 5px #0000007B;
  color: white;
  /* margin-top: 335px; */ //Alternative to center
  position: absolute;
  top: 50%;
  margin-top: 20px;
  transform: translateY(-50%);
  transition: opacity 0.3s;
  .has-opened-menu & {
    opacity: 0;
    // animation: fade-out-zoom-out .4s ease-in-out;
    // animation-fill-mode: forwards;
  }
}


.fullscreen-menu-wrapper {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: rgba(0,0,0,0.8);
  color: white;
  height: 100vh;
  width: 100vw;

  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  display: none;
  opacity: 0;

  .has-opened-menu & {
    display: flex;
    animation: fade-in-zoom-out 0.3s ease-in-out;
    animation-fill-mode: forwards;
  }
  .close-menu-button {
    // font-size: 120px;
    // transition: transform 0.3s;
    // transform: rotate(0deg);
    // top: 60px;
    // height: 100px;
    width: 85px;
    height: 55px;
    cursor: pointer;
    img { width: 100%; }

    background-position: center center;
    background-size: cover;
    background-image: url(../assets/images/close_button_menu_white.svg);
    &:hover {
      background-image: url(../assets/images/close_button_menu_hover.svg);
    } 
    @media (max-width: $bp-desktop) {
      width: 65px;
    }
    .has-opened-menu & { 
      // transform: rotate(90deg);
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  ul.menu {
    list-style-type: none;
    padding-left: 0;
  }
  li {
    text-transform: uppercase;
    font-size: 53px;
    letter-spacing: 0.53px;
    font-weight: 900; 
    margin-bottom: 23px;
    line-height: 1;
    white-space: nowrap;

    &:hover {
      color: $teal;
    }

    @media (max-width: $bp-tablet) {
      font-size: 30px;
      letter-spacing: 0.3px;
    }
    @media (max-width: 380px) {
      font-size: 24px;
    }
    span { // number
      margin-right: 45px;
      letter-spacing: -1.06px;
      font-weight: 100; 
      @media (max-width: $bp-tablet) {
        margin-right: 25px;
      }
    }
  }
}

//no flexbox
.no-flexbox .has-opened-menu .fullscreen-menu-wrapper {
  display: block;
  .close-menu-button {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .menu {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}




/* full width video: 
https://stackoverflow.com/questions/10797632/simulate-background-sizecover-on-video-or-img
*/
.video--header {
  height: 100vh;
  min-width: 100%;
  object-fit: cover;

  /* avoid jumping when browser address bar disappears */
  /* transition: height 999999s; */

  // @media screen and (min-width: 1400px) and (max-height: 800px) {
  //   width: 100vw;
  //   height: auto;
  // }
}

// avoid ugly overflow if height is too little
// @media screen and (min-width: 1400px){
//   header, .modal--start, video {
//     min-height: 800px;
//   }
// }


@media screen and (max-width: 680px) { 
  .video--header { 
    height: 177.77777778vw;
    min-height: 100%;
  }
}


video.centered, iframe.centered {
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */

}
video.hidden, iframe.hidden {
  display:none;
}

.mute-button {
  display: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.85;
  cursor: pointer;
  bottom: 20px;
  height: 30px;

  @media (min-width: 680px) {
    bottom: 25px;
    height: 35px;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */ 
    // bottom: 120px;
  }
}

.mute-button.hidden {
  opacity: 0;
  display: none;
  cursor: default;
}
/* .mute-button:hover {
  cursor: pointer;
} */
