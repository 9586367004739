
// @font-face {
//   font-family: 'DIN Next LT Pro';
//   src: url('../assets/font/DINNextLTPro-UltraLightIt.woff2') format('woff2'),
//       url('../assets/font/DINNextLTPro-UltraLightIt.woff') format('woff');
//   font-weight: 100;
//   font-style: italic;
//   font-display: fallback
// }


@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../assets/font/DINNextLTPro-UltraLight.woff2') format('woff2'),
      url('../assets/font/DINNextLTPro-UltraLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: fallback
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../assets/font/DINNextLTPro-Light.woff2') format('woff2'),
      url('../assets/font/DINNextLTPro-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: fallback
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../assets/font/DINNextLTPro-Bold.woff2') format('woff2'),
      url('../assets/font/DINNextLTPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: fallback
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../assets/font/DINNextLTPro-Black.woff2') format('woff2'),
      url('../assets/font/DINNextLTPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: fallback
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../assets/font/DINNextLTPro-Regular.woff2') format('woff2'),
      url('../assets/font/DINNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback
}