.section--attitude {

  .parallax-img-wrapper {
    position: absolute;
    right: -12%;
    // top: 370px;
    top: 200px;
  

    @media (max-width: 1500px) {
      top: 180px;
      // right: 10%;
    }
  
    @media (max-width: $bp-desktop) {
      top: 220px;
    }
    @media (max-width: $bp-tablet) {
      right: 8%;
      top: 280px;
    }
    @media (max-width: 500px) {
      right: 18%;
      // width: 340px;

      top: 390px;
    }
  
    @media (max-width: 500px) {
      right: auto;
      left: 10%;
    }
   
  }

.bg-image--attitude {
  max-width: 810px;
  transform: rotate3d(0, 0, 1, -15deg);
  // @media (min-width: 1921px) {
  //  top: 157px;
  // }


  @media (max-width: $bp-tablet) {
    width: 400px;
    height: auto;
  }
  @media (max-width: 500px) {
    // width: 340px;
    height: auto;
  }

}
  .text--attitude {
    @media (max-width: $bp-tablet) {
      margin-top: 320px;
    }
  }
  p.end-line {
    // margin-top: 75px;
    @media (max-width: $bp-tablet) { 
      // margin-top: 45px;
    }
  }
}