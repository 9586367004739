$person-img-width-lg: 273px;
$person-img-width-tablet: 108px;



.section--contact {

  a {
    text-decoration: none;
    color: #fff;
    &:hover { color: $lightgrey; }
  }

  .text--right {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    // margin-left: colToPx(3)+px;
    margin-left: pxToPercent(colToPx(3), $inner-width-lg);
    @media (min-width: 1921px) {
      margin-left: colToPx(3)+px;
    }
    @media (max-width: $bp-desktop) { 
      margin-left: 0;
      margin-right: 0;
    }
  
  }

  .parallax-img-wrapper { 
    position: absolute;
    top: 290px;
    left: pxToVw(200, $outer-width-lg);;
    // left: -470px;
    // left: pxToVw(-470, $outer-width-lg);

    @media (min-width: 631px) { 
      // transform: translateY(-1vw);
      top: 80px;
      left: auto;
      right: pxToVw(10, $outer-width-lg);

    }


  
    @media (min-width: $bp-tablet) { 
      // transform: translateY(-1vw);
      right: pxToVw(-300, $outer-width-lg);

    }

    @media (min-width: $bp-desktop) {
      left: pxToVw(-800, $outer-width-lg);

     }


    @media (min-width: $bp-large) {
    }

    @media (max-width: 630px) {
      // top: 140px;
    }
  }

  .bg-image__contact  {
    
   max-width: 1400px;
    // width: 800px;
    width: pxToVw(1400, $outer-width-lg);
    transform: rotate(-15deg);

    @media (min-width: $bp-large) {
     
    }
  
    @media (max-width: $bp-tablet) { 
      // transform: translateY(-1vw);
      // top: -30px;
    }
  
    @media (max-width: 630px) {
      width: 300px;
    }
  }
  


  

  .headline--section {
    margin-bottom: 75px;
    @media (max-width: 630px) { 
     margin-bottom: 305px;
    }
  }
  .text__support{
    margin-top: 100px;
    @media (max-width: $bp-tablet) {
      margin-top: 60px;
    }
  } 
  .end-line {
    margin-top: 45px;
  } 

  .persons { 
    position: relative; 
    margin-top: 45px;
    @media (max-width: $bp-tablet) { 
      margin-top: 25px;
    }
  }
  
  .person--half-right {

    &-lg {
      transform: translateX($person-img-width-lg*0.5);
      @media (max-width: $bp-tablet) {
        transform:none;
      }
    } 
    
    &-tablet {
      @media (max-width: $bp-tablet) {
        transform: translateX($person-img-width-tablet*0.5);
      }
    }
  }

  .person-row {
    position: relative;
    margin-top: 10px
  }

  .person {
    display: flex;
    align-items: center;
    font-weight: 200;

    @media (max-width: $bp-phone) { 
      margin-top: 20px;
    }

    img {
      width: $person-img-width-lg;
      height: auto;
      object-fit: cover;
      // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
      clip-path: polygon(50% 1%, 99% 25%, 99% 74%, 50% 99%, 1% 73%, 0% 26%); // smaller version of the polygon

      @media (max-width: 1645px) {
        width: 200px;
      }
      @media (max-width: $bp-tablet) {
        width: 108px;
      }

      @media (max-width: $bp-phone) { 
        width: 75px;
      }
    }

    .person__description {
      margin-top: -50px;
      margin-left: 30px;
      @media (max-width: 1645px) {
        margin-top: -20px;
      }

      @media (max-width: 980px) {
        transform: scale(0.8);
        transform-origin: left;
        margin-left: (30px * 0.8);
      }
      @media (max-width: $bp-tablet) {
        transform: none;
        margin-left: 15px;
        margin-top: -10px;
      }
    }
    .person__name {
      font-size: 35px;
      text-transform: uppercase;
      
      letter-spacing: pxToEm(35, 0.35);
      margin-bottom: 0;

      @media (max-width: $bp-tablet) { 
        font-size: 15px;
        letter-spacing: pxToEm(15,0.15);
        font-weight: 700;
        text-transform: none;
      }
    }

    .person__title {
      font-size: 22px;
      letter-spacing: pxToEm(22, 0.2);
      margin-top: 5px;
      @media (max-width: $bp-tablet) {
        font-size: 15px;
        letter-spacing: pxToEm(15,0.15);
        font-weight: 200;
      }
    }

    a {
      font-size: 35px;
      letter-spacing: 0;
      font-weight: 900;
      text-transform: uppercase;
      &:hover { color: $lightgrey; }

      @media (max-width: $bp-tablet) {
        font-size: 15px;
        letter-spacing: pxToEm(15,0.15);
        font-weight: 900;
        margin-bottom: 10px;
      }
    }
    
  }

  .person--second {
    position: absolute;
    top: 0;
    left: pxToPercent(colToPx(6), $inner-width-lg);

    @media (max-width: 1528px){
      position: static;
      margin-left: pxToPercent(colToPx(6), $inner-width-lg);
    }
    // @media (max-width: 1320px){ 
    //   margin-left: pxToPercent(colToPx(5),$inner-width-lg);
    // }
    @media (max-width: $bp-desktop_lg){ 
      margin-left: pxToPercent(colToPx(3, "desktop_lg"),$inner-width-desktop_lg);
    }
  
    @media (max-width: $bp-tablet){ 
      margin-left: 0;
      // margin-left: pxToPercent(colToPx(1, "tablet"),$inner-width-tb);
    }
    // @media (max-width: 610px){ 
    //   margin-left: pxToPercent(colToPx(1, "tablet"),$inner-width-tb);
    // }
    @media (max-width: $bp-phone) { 
      margin-left: 0;
      transform: none;
    }
  }

  .person--third {
    margin-left: pxToPercent(colToPx(0),$inner-width-lg);
    @media (max-width: 1528px){ 
      // margin-left: pxToPercent(colToPx(5),$inner-width-lg);
      margin-left: 0;
      transform: none;;
    }
    // @media (max-width: 1320px){ 
    //   margin-left: pxToPercent(colToPx(4),$inner-width-lg);
    // }
    @media (max-width: $bp-desktop_lg){ 
      margin-left: 0;
    }
    @media (max-width: $bp-tablet){ 
      margin-left: 0;
    }
  }

  .person--fourth {
    position: absolute;
    top: 0;
    left: pxToPercent(colToPx(6), $inner-width-lg);
    @media (max-width: 1528px) { 
      position: relative;
      left: 0;
      top: auto;
      margin-left: pxToPercent(colToPx(6), $inner-width-lg);
      transform: none;
    }

    @media (max-width: $bp-desktop_lg) { 
      margin-left: pxToPercent(colToPx(3, "desktop_lg"),$inner-width-desktop_lg);
    }
    @media (max-width: $bp-tablet){ 
      position: relative;
      margin-left: 0;
      transform: translateX($person-img-width-tablet*0.5);
    }

    @media (max-width: $bp-phone) { 
      margin-left: 0;
      transform: none;
    }
  }
}