/* can do:  structure file scss nested like */
@use "sass:math";

@import "colors";
@import "embla-slider";
@import "header-slider";
@import "functions";




// posible improvement: use maps or list
$section-side-padding-large: 105px;
$outer-width-lg: 1920;
$inner-width-lg: $outer-width-lg - 2 * math.div($section-side-padding-large, 1px);

$section-side-padding-desktop_lg: 80px;
$outer-width-desktop_lg: 1280;
$inner-width-desktop_lg: $outer-width-desktop_lg - 2 * math.div($section-side-padding-desktop_lg, 1px);

$section-side-padding-tb: 40px;
$outer-width-tb: 414;
$inner-width-tb: $outer-width-tb - 2 * math.div($section-side-padding-tb, 1px);


*, *:before, *:after {
  box-sizing: border-box;
}

html {
  // scroll-behavior: smooth;
}

body {
  font-family: 'DIN Next LT Pro', sans-serif;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  // background-color: whitesmoke;
}

strong {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover { color: $teal; }
}
.blurred {
  filter: blur(4px);
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}
.full-section-width {
  margin-left: -$section-side-padding-large;
  margin-right: -$section-side-padding-large;
}

.grid-overlay {
  display: grid;
  display: none;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 15px;
  position: fixed;
  top: 0;
  width: calc(100% - 210px);
  max-width: calc(1920px - 210px);
  height: 100%;
  margin: 0 105px;
  opacity: 0.3;
  pointer-events: none;
  z-index: 999999;
  @media (max-width: $bp-desktop_lg) {
    grid-template-rows: 100vh;
    width: calc(100% - #{2 * $section-side-padding-desktop_lg});
    margin: 0 $section-side-padding-desktop_lg;
  }

  @media (max-width: $bp-desktop) {
    grid-gap: 10px;
  }

  @media (max-width: $bp-tablet) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 100vh;
    width: calc(100% - #{2 * $section-side-padding-tb});
    margin: 0 $section-side-padding-tb;
    grid-gap: 10px;
  }
  
  div {
    background-color: deepskyblue;
    width: 100%;
   
  }
}
/* preload hover images */
// body:hover:after, .logo:after {
//   display: block;
//   content: "";
//   width:1px;
//   height: 1px;
//   position: absolute;
//   bottom: 0;
//   z-index: -1;
//   visibility: hidden;
//   background-image: url(../images/Go-Button_Hover.svg);
//  }

.preload-images {
  display: none;
}

.logo {
  background-image: url(../assets/images/Logo_CHRW_white.svg);
  background-repeat: no-repeat;
  background-position: center 0px;
  width: 104px;
  height: 121px;

  &:hover {
    background-image: url(../assets/images/Logo_CHRW_teal.svg);
  }
}

.headline {
  position: relative;
  z-index: 999;
  text-transform: uppercase;
  font-size: 250px;
  font-size: pxToVw(250, $outer-width-lg);
  font-weight: 900;
  // color: white;
  letter-spacing: 2.5px;
  line-height: 230px;
  line-height: pxToEm(250,230);
  @media (min-width: $bp-large) {
    font-size: 250px;
  }
  @media (max-width: $bp-tablet) {
    font-size: 60px;
    letter-spacing: 0.6px;
  }
  @media (max-width: 380px) {
    font-size: 45px;
  }
  span {
    display: block;
    font-size: 90px;
    font-size: pxToVw(90, $outer-width-lg);
    font-weight: 100;
    line-height: 0;
    letter-spacing: 0;
    margin-top: 55px;
    margin-top: pxToVw(55, $outer-width-lg);
    white-space: nowrap;
    @media (min-width: $bp-large) {
      font-size: 90px;
      margin-top: 55px;
    }
    @media (max-width: $bp-tablet) {
      font-size: 25px;
    }
  }
}
section {
  overflow: hidden;
  background-color: #00C3D2;
}

// section, footer {
.max-width-wrapper  {
  overflow-y: hidden;
  padding: 100px $section-side-padding-large 145px $section-side-padding-large;
  background-color: #00C3D2;
  color: white;
  position: relative;
  max-width: 1920px;
  margin: auto;
  overflow: visible;

  @media (max-width: $bp-desktop_lg) {
    padding: 100px $section-side-padding-desktop_lg 145px $section-side-padding-desktop_lg;
  }

  @media (max-width: $bp-tablet) {
    padding: 40px $section-side-padding-tb 140px $section-side-padding-tb;
  }
  
  .section--white & {
    background-color: white;
    color: black;
  }

  .footer--main-page & {
    padding-bottom: 0;
  }
}

.section--white {
  background-color: white;
  color: black;
  .max-width-wrapper {
    // background-color: white;
    // color: black;
  }
}

.headline--section {
  margin-top: 100px;
  margin-top: pxToVw(100, $outer-width-lg);
  margin-bottom: 150px;
  margin-bottom: pxToVw(150, $outer-width-lg);


  @media (min-width: $bp-large) { 
    margin-top: 100px;
    margin-bottom: 130px; 
  }

  @media (max-width: $bp-tablet) { 
    margin-top: 50px;
    margin-bottom: 65px; 
  }

  &.headline--section-small {
    margin-top: 90px;
    margin-bottom: 80px;
    font-size: 120px;
    font-size: pxToVw(120, $outer-width-lg);
    letter-spacing: 1.2px;
    line-height: 100px;
    line-height: pxToEm(120, 100);
    
    
    @media (min-width: $bp-large) { 
      font-size: 120px; 
    }
    @media (max-width: $bp-tablet) { 
      font-size: 60px; 
      letter-spacing: 0.6px;
    }
    @media (max-width: $bp-phone) { 
      font-size: 40px; 
      letter-spacing: 0.6px;
    }
    
    span { 
      font-size: 40px;
      font-size: pxToVw(40, $outer-width-lg);
      margin-top: 40px;
      margin-top: pxToVw(40, $outer-width-lg);
      @media (min-width: 1921px) { 
        font-size: 40px; 
        margin-top: 40px;
      }
      @media (max-width: $bp-tablet) { 
        font-size: 25px; 
      }
      @media (max-width: $bp-phone) { 
        font-size: 20px; 
      }
    }
  }
}

.section-name-wrapper {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  text-align: center;
  font-size: 29px;

  @media (max-width: $bp-tablet) {
      font-size: 20px;
  }
  
  .section-number {
    font-weight: 200;
    letter-spacing: -0.58px;
    font-size: inherit;
  }
  .section-name {
    font-weight: 900;
    letter-spacing: 0.29px;
    line-height: 1;
    margin-top: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: inherit;
    @media (max-width: $bp-tablet) {
      margin-top: 2px;
    }
    
  }
}

// .headline-flex-wrapper {
//   display: flex;
//   justify-content: flex-end;
// }



// .section--attitude,
// .section--contact {
//   background-image: url(../assets/images/Background_1920.jpg);
//   background-size: cover;
//   @media (max-width: $bp-desktop) {
//     background-image: url(../assets/images/Background_1024.jpg);
//   }
//   @media (max-width: 768px) {
//     background-image: url(../assets/images/Background_768.jpg);
//   }
// }

.text {
  position: relative;
  font-weight: 200;
  font-size: 22px;
  line-height: pxToEm(22,35);
  letter-spacing: 0.22px;

  @media (max-width: $bp-tablet) {
    font-size: 15px;
    letter-spacing: 0.15;
  }
  
  p { 
    max-width: 930px;
    width: pxToVw(930, $outer-width-lg);;
    
    margin-top: 0;
    margin-bottom: 20px; 

    @media (max-width: $bp-desktop) {
      width: 100%;
    }

    @media (max-width: $bp-tablet) {
      width: 100%
    }
  }

  p.end-line {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 35px;
    margin-top: 45px;
    // margin-top: pxToVw(75, $outer-width-lg);
    margin-bottom: 0;

    @media (min-width: 1921px) {
      // font-size: 35px;
      // margin-top: 75px;
    }
    @media (max-width: $bp-tablet) {
      font-size: 20px;
    }
  }
}

.text--right {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  margin-left: colToPx(5)+px;
  margin-left: pxToPercent(colToPx(5), $inner-width-lg);
  @media (min-width: 1921px) {
    margin-left: colToPx(5)+px;
  }
  @media (max-width: $bp-desktop) { 
    margin-left: 0;
    margin-right: 0;
  }

}

@import "01-attitude";
@import "02-performance";


.bg-image {
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transform-origin: top;
}




// .section--attitude {
//   .text--attitude {
//     @media (max-width: $bp-tablet) {
//       margin-top: 320px;
//     }
//   }

//   p.end-line {
//     margin-top: 75px;
//   }
// }


@import "03-projects";
// @import "04-responsibility";
@import "04-brands_clients.scss";
// @import "06-team";


@import "05-contact";
@import "footer";


//ToDo: An entsprechende Stelle kopieren
body {
  max-width: none;
}


// sollte sich mit der footer regel kombinieren lassen
// .max-width-wrapper {
//   overflow-y: hidden;
//   padding: 100px 105px 145px 105px;
//   background-color: black;
//   color: white;
//   position: relative;
//   max-width: 1920px;
//   margin: auto;
//   overflow: visible;
// }