.section--projects.loading-height {
  min-height: 1170px;

  @media (min-width: 376px) {
    min-height: 850px;
  }
  @media (min-width: 540px) {
    min-height: 1020px;
  }

  @media (min-width: 667px) {
    min-height: 1130px;
  }
  @media (min-width: 768) {
    min-height: 1020px;
  }
  @media (min-width: 1014px) {
    min-height: 1170px;
  }
  @media (min-width: 1025px) {
    min-height: 1400px;
  }
  @media (min-width: 1400px) {
    min-height: 1800px;
  }

  @media (min-width: 1700PX) {
    min-height: 2200px;
  }
}


ul.text--insta-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  padding-left: 0;
  li {
    width: auto;
    margin-right: 20px;
    cursor: pointer;
    &.active-insta-filter {
      text-decoration: underline;
      pointer-events: none;
    }
    display: none;
  }

}
.widget__wrapper {
  // width: 100% !important;
  position: relative;
  img.iframe-loader {
    position: absolute;
    margin: 0 auto;
    width: 50px;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    display: none;
  }

  &.widget-loading {
    img.iframe-loader {
      display: inline;
    }
    
  }

  .widget {
    // display: none;
    opacity: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
    &.widget--preloader {
      opacity: 0.2;
      height: 0 !important;
    }
    &.widget--active {
      display: block;
      opacity: 1;
      height: auto;
      position: static;
      pointer-events: all;
      // background-color: greenyellow;
    }
  }
}

//*** eapps Widget CSS ****
.eapps-instagram-feed-container {
  background-color: transparent;
}

.eapps-instagram-feed-posts-grid-load-more {
  // display: none;
  background-color: transparent !important;
}
.eapps-instagram-feed-posts-grid-load-more-text {
  // display: none;
  margin-top: 40px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 35px; //same size as email adress typo
  &:hover {
    color: $lightgrey;
  }

  @media (max-width: $bp-tablet) {
    font-size: 20px; //same size as Section headline
  }
}


.eapps-instagram-feed-popup-inner {
  padding-bottom: 80vh !important;
}

#sw-lightbox-overlay.open {
  #sw-image-container {
    @media (max-width: 991px) { 
      display: block !important;
    }
  }
}

#sw-lightbox-overlay {

  #sw-image-container {
    // width: 100% !important;
    // display: flex;
    // justify-content: center;
    
  }

  #sw-image-details {
    @media (min-width: 992px) { 
      overflow: scroll;
    }
  }

  #sw-profile-picture {
    flex-shrink: 0;
    border-radius: 100%;
  }


  .icon-arrow, #sw-close-light {
    cursor: pointer;
  }

  #sw-close-light {
    top: 30px !important;
    right: 25px !important;
    transform: scale(1.3);

    svg {
      filter: drop-shadow( 0px 0px 1px rgba(50, 50, 50, 0.6));
    }
  }

  .icon-arrow--left {
    transform: rotate(-135deg) scale(2) !important;
  }
  .icon-arrow--right {
    transform: rotate(45deg) scale(2) !important;
  }
}

//disable click events on images and suchlike
#sw-lightbox-overlay.open #sw-image, 
#sw-lightbox-overlay.open #sw-video, 
#sw-lightbox-overlay.open #sw-youtube-video {
  pointer-events: none !important;
  @supports (object-fit: cover) { 
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
}

// Carousel NAvigation Arrows
#sw-lightbox-overlay #sw-lightbox-carousel-navigation-left, #sw-lightbox-overlay #sw-lightbox-carousel-navigation-right {
  cursor: pointer;
}

// Gap between tiles
.eapps-instagram-feed-posts-view > div {
  margin: 4px !important;
}