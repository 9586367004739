.section--clients_awards {

  .parallax-img-wrapper {
    position: absolute;
    right: 3%;
    top: 20px;


    @media (max-width: $bp-desktop) {
      top: 220px;
    }
    @media (max-width: $bp-tablet) {
      right: 6%;
      top: 110px;
    }
  
    @media (max-width: 500px) {
      right: 2%;
    }

  }
  .bg-image--clients_awards {
    transform: rotate(-34deg);
    
    max-width: 439px;
    width: pxToVw(700, $outer-width-lg);
  
    @media (max-width: $bp-tablet) {
      width: 280px;
      height: auto;
    }
  
  }

  .headline-content-margin {
    @media (max-width: $bp-desktop) { }
    margin-top: 250px;
  }
  .text--clients_awards {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: $bp-desktop) { 
      flex-direction: column;
      align-items: center;
      display: flex;
    }


    p { 
      max-width: 930px; 
      width: pxToVw(960, $outer-width-lg);
      @media (max-width: $bp-desktop) {
        max-width: none;
        width: 100%;
      }
    }
  }
  
  .logo-fader {
    position: absolute;
    left: -55%;
    top: 50%;
    z-index: 0;

    transform: translateY(-50%);

    width: 53%;
    max-width: 500px;
    
    padding-top: 26%;
     

    @media (max-width: 1200px) {
      left: -75%;
      width: 75%;
      padding-top: 38%;
    }
    @media (max-width: $bp-desktop) { 
      position: static;
      transform: translate3d(0,0,0) !important; // quick disabling of paralax
      margin-bottom: 80px;
      margin-top: 30px;
      padding-top: 30%;
      
    }
    @media (max-width: $bp-tablet) { 
      margin-top: 0;
      margin-bottom: 50px;
    }


    > img {
      position: absolute;
      top: 0;

      width: 100%;
      height: auto;
      transition: opacity 1s;
    }

    > img + img {
      opacity: 0;
    }

  }

  .headline--section {
    @media (max-width: $bp-desktop) {
      margin-bottom: 50px;
    }
  }
  .headline--section-small {
    margin-left: colToPx(2) + px;
    margin-left: pxToVw(colToPx(2), $outer-width-lg);
    margin-top: 205px;
    @media (max-width: $bp-tablet) { 
      margin-left: 0;
      margin-top: 70px;
      white-space: nowrap;
    }
  }

  .customers {
    display: grid;
    grid-template-columns:  repeat(4, 1fr);
    // grid-template-rows:  repeat(3, 1fr);
    // grid-gap: pxToVw(95, $outer-width-lg);
    grid-gap: 25px;
    margin-left: pxToVw(colToPx(2), $outer-width-lg);
    margin-right: pxToVw(colToPx(2), $outer-width-lg);
    margin-top: 105px;

    @media (max-width: 1280px) { 
      grid-template-columns:  repeat(3, 1fr);
      margin-left: pxToVw(colToPx(1.5), $outer-width-lg);
      margin-right: pxToVw(colToPx(1.5), $outer-width-lg);
    }

    @media (max-width: $bp-tablet) { 
      margin: 20px 0 0 0;
    }


    @media (min-width: 1921px) {
      margin-left: colToPx(2)+px;
      margin-right: colToPx(2)+px;
      grid-gap: 20px;
    }
    // img {
    //   width: 100%;
    //   height: auto;
    // }

    > .logo-wrapper {
      position: relative;
      width: 100%;
      padding-top: 70%;
      > img {
        width: 100%;
        height: auto;
        top: 0;
        position: absolute;
        // transition: opacity 0.3s;

        // & + img { opacity: 0; }
        transition: opacity 0.8s;
      }
    }

    .logo--invisible { opacity: 0; }
    .logo--visible { opacity: 1; }
  }

  .no-cssgrid & .customers {
    display: block;
    > .logo-wrapper {
      width: 250px;
      padding: 0px;
      padding-top: 15%;
      display: inline-block;
    }

    > .logo-wrapper img {
      padding: 20px;
    }
  }


  // .XXXlogo-wrapper--fading {
  //   $duration: 3s; // Time to cycle everything
  //   > * {
  //     @for $i from 1 through $slides {
  //       &:nth-child(#{$i}) {
  //         animation: fade#{$i} $duration infinite;
  //         // animation-delay: #{$i * 2s};
  //         animation-fill-mode: forwards;
  //       }
  //       @keyframes fade#{$i} {
  //         @for $j from 0 through $slides {
  //           #{percentage($j/$slides)} {
  //             opacity: if($i==$j+1 or ($i==1 and $j==$slides), 1, 0);
  //           }
  //         }
  //       }
  //     }
  //   }
  // } // END logo-wrapper--fading 

}