@import "_functions";
$slide-width: 93%;
$slide-width-desktop_large: 85%;
$slide-width-tablet: 85%;
$slide-width-phone: 90%;
$transform-scale: 1.1;
.embla {
  position: relative;
  margin-top: 80px;
  margin-bottom: 65px;
  @media (max-width: $bp-tablet) {
    margin-top: 45px;
  }
}

.embla__nav-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  max-width: 2030px;;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.embla__nav {
  // background-color: grey;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: all;

  .embla:hover & {
    opacity: 1;
  }

  &.embla__nav--prev {
    left: 100px;
    transform: rotate(180deg) translateY(50%);
    filter: drop-shadow(0 -3px rgba(0, 0, 0, 0.24));
  }

  &.embla__nav--next {
    right: 100px;
    filter: drop-shadow(0 3px rgba(0, 0, 0, 0.24));
  }

  // when hovering nav keep hovering status of caption and zoom-bg-image
  &:hover {
    cursor: pointer;
    ~ .embla__viewport .is-selected .caption { opacity: 1; }
    ~ .embla__viewport .is-selected .zoom-bg-image {
      transform: scale($transform-scale);
    }
  }

  .device-is-mobile & {
    display: none;
  }
}


.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  display: flex;
  // center slide (see .embla__slide flex: 0 0 80%;)
  will-change: transform;

  list-style-type: none;
  margin: 0;
  padding: 0;
}

$slide-padding: 7.5px;
$slide-padding-tablet: 4.5px;
$slide-padding-phone: 2.5px;
.embla__slide {
  position: relative; /* Needed if loop: true */
  flex: 0 0 auto; /* Choose any slide width */
  width: $slide-width;
  max-width: 1920px;
  overflow: hidden;
  height: 560px;
  width: 575px;
  position: relative;
  padding-left: $slide-padding;
  padding-right: $slide-padding;
  cursor: pointer;

  @media (max-width: $bp-desktop_lg) {
    width: $slide-width-desktop_large;
  }

  @media (max-width: $bp-tablet) {
    width: $slide-width-tablet;
    padding-left: $slide-padding-tablet;
    padding-right: $slide-padding-tablet;
    /*height: 90vh;*/
    max-height: 800px;
  }

  @media (max-width: $bp-phone) {
    width: $slide-width-phone;
    padding-left: $slide-padding-phone;
    padding-right: $slide-padding-phone;
  }
  /*&.is-selected:hover .zoom-bg-image {
    transform: scale($transform-scale);
  }*/

  .zoom-bg-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .zoom-bg-image {
    height: 100%;
    background-size: cover;
    background-position: center bottom;
    transition: transform 0.8s;
    // transform-origin: left bottom;
    transform-origin: center center;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 33%;
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
      opacity: 0.35;
    }
  }
  .zoom-bg-wrapper:hover {
    cursor: pointer;
    .device-is-mobile & {
       transform: none;
    }
    + .caption { opacity: 1; }

  }


  .caption {
    opacity: 0;
    position: absolute;
    bottom: 25px;
    left: 40px;
    color: white;
    font-size: 48px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    margin-top: 0;
    margin-bottom: 0;
    transition: opacity 0.5s;
    pointer-events: none;





    // .overlay--opened.& {
    //   opacity: 0;
    // }

    @at-root .overlay--opened .caption {
      opacity: 0 !important;
    }
    @media (max-width: $bp-desktop) {
      bottom: 20px;
      font-size: 30px;
      letter-spacing: 0.3px;
    }

    @media (max-width: $bp-tablet) {
      left: 25px;
    }

    /*br {
      display: none;
      @media (max-width: $bp-desktop) {
        display: inline;
      }
    }*/

    .device-is-mobile & {
      opacity: 1;
    }

    .device-is-mobile .overlay--opened & {
      opacity: 0;
    }

    span {
      font-weight: 100;
      margin-right: 15px;
      @media (max-width: $bp-desktop) {
        display: block;
        font-size: 60px;
        letter-spacing: -1.2px
      }
    }
  }
}

.fancybox__caption {
  font-family: 'DIN Next LT Pro', sans-serif;
  font-weight: 800;
  letter-spacing: 0.8px;
  font-size: 1.3em;
  text-transform: uppercase;
}


.embla__slide--1 {
    .zoom-bg-image {
        background-image: url(../assets/images/expertise-carousel/01_cultural-events_cwstudio.jpg);
    }
}

.embla__slide--2 {
    .zoom-bg-image {
        background-image: url(../assets/images/expertise-carousel/02_corporate-events_cwstudio.jpg);
    }
}

.embla__slide--3 {
    .zoom-bg-image {
        background-image: url(../assets/images/expertise-carousel/03_racing-experience_cwstudio.jpg);
    }
}

.embla__slide--4 {
    .zoom-bg-image {
        background-image: url(../assets/images/expertise-carousel/04_golf-events_cwstudio.jpg);
    }
}

.embla__slide--5 {
    .zoom-bg-image {
        background-image: url(../assets/images/expertise-carousel/05_event-marketing-communication_cwstudio.jpg);
    }
}

.embla__slide--6 {
    .zoom-bg-image {
        background-image: url(../assets/images/expertise-carousel/06_event-media-production_cwstudio.jpg);
    }
}

.embla__slide--7 {
    .zoom-bg-image {
        background-image: url(../assets/images/expertise-carousel/07_event-set-design_cwstudio.jpg);
    }
}








//*******************************
//*********** OVERLAY ***********
//*******************************


.embla__slide {
  &.overlay--opened .overlay {
    display: block;
    animation: fade-in-zoom-out 0.3s ease-in-out;
  }


  .overlay {
    display: none;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    z-index: 9;
    height: 100%;
    width: 100%;
    width: calc(100% - #{$slide-padding*2});
    color: white;
    padding: 20px 20px;
    overflow: hidden;
    // text-transform: uppercase;

    @media (max-width: $bp-desktop) {
      padding: 40px;
    }
    @media (max-width: $bp-tablet) {
      padding: 30px 20px;
      width: calc(100% - #{$slide-padding-tablet*2});
    }
    @media (max-width: $bp-phone) {
      width: calc(100% - #{$slide-padding-phone*2});
    }
    // &.overlay--opened {
    //   display: block;
    //   animation: fade-in-zoom-out 0.3s ease-in-out;
    // }

    .overlay__close {
      position: absolute;
      top: 50px;
      right: 60px;
      font-size: 80px;
      font-weight: 900;
      line-height: 1em;
      transition: color 0.3s;
      cursor: pointer;
      &:hover { color: $teal; }

      @media (max-width: $bp-desktop) {
        font-size: 30px;
        top: 40px;
      }
      @media (max-width: $bp-tablet) {
        top: 30px;
      }
    }

    .overlay__headline {
      display: flex;
      align-items: flex-end;
      margin-top: 0;
      @media (max-width: $bp-desktop) {
        flex-direction: column;
        align-items: flex-start;
      }
      span:first-child {
        font-size: 177px;
        font-weight: 100;
        letter-spacing: -8.85px;
        line-height: 0.75em;
        margin-bottom: -5px;
        @media (max-width: $bp-desktop) {
          font-size: 60px;
          letter-spacing: -1.2px;
        }
      }
      span:last-child {
        // font-size: 80px;
        // font-size: 50px;
        font-size: 68px;
        transform: translateY(3px);
        font-weight: 900;
        // line-height: 80px;
        line-height: 1.1em;
        margin-left: 35px;
        @media (max-width: $bp-desktop) {
          // font-size: 30px;
          font-size: 25px;
          letter-spacing: 0.3px;
          line-height: 32px;
          margin: 10px 0px 20px 0px;
        }
      }
    }

    .overlay__text {
      // font-size: 80px;
      // font-size: 50px;
      font-size: 40px;
      // font-size: pxToVw(80, 1920);
      letter-spacing: 0.8px;
      letter-spacing: pxToEm(80, 0.8);
      line-height: pxToEm(80,80);
      font-weight: 900;
      margin-top: 45px;
      margin-bottom: 50px;


      &.overlay__text--list {
        padding-left: 0;
        list-style-type: none;
        li {
          margin-bottom: 0.1em;
          font-weight: 200;
          span {
            display: block;
            font-weight: 900;
            margin-bottom: 5px;
            @media (max-width: $bp-desktop) {
              margin-bottom: 3px;
            }
          }
        }
      }

      @media (max-width: $bp-desktop) {
        // font-size: 30px;
        font-size: 25px;
        // line-height: 32px;
        line-height: 28px;
        letter-spacing: 0.3px;
        margin-top: 25px;
      }
      span {
        font-weight: 200;
      }
    }

    .overlay__text--contact {
      margin-top: 0px;
      margin-bottom: 0px;
      text-transform: uppercase;
      span {
        font-weight: inherit;
        position: absolute;
        transition: opacity 0.3s;
      }

      a {
        color: inherit;
        text-decoration: inherit;
      }
      span:last-child {
        position: relative;
        visibility: hidden;
      }
      span:nth-child(2){
        color: $teal;
        opacity: 0;
      }

      &:hover  {
        span:first-child {
          opacity: 0;
        }
        span:nth-child(2) {
          opacity: 1;
        }
      }
    }

    .overlay__close,
    .overlay__text--list,
    .overlay__headline {
      cursor: pointer;
    }
  }
}
