
@use "sass:math";

.XXXheader-slider {
  // position: relative;
  // overflow: hidden;

	$slides: 7;
	$delay: 14s; // Time to cycle everything

	> * {
		// position: absolute;
		// left: 0; right: 0;
		// top: 0; bottom: 0;
		// background-size: cover;

		@for $i from 1 through $slides {
			&:nth-child(#{$i}) {
				animation: fade#{$i} $delay infinite;
			}
			@keyframes fade#{$i} {
				@for $j from 0 through $slides {
					#{percentage(math.div($j, $slides))} {
						opacity: if($i==$j+1 or ($i==1 and $j==$slides), 1, 0);
					}
				}
			}
		}
  }
  
}

.header-slider--style {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  > div {
    position: absolute;
		left: 0; right: 0;
    top: 0; bottom: 0;
    background-size: cover;
    background-position: center;

    font-size: 0px;
    color: transparent;

    transition: opacity .5s ease-in;
  }

  div + div { opacity: 0; } // show first image first
}


.top_slider_dtm {
    background-image: url(../assets/images/top_slider/01_top-slider_dtm.jpg);
    @media (max-width: $bp-desktop_lg) {
      background-image: url(../assets/images/top_slider/01_top-slider_dtm.jpg?width=1280);
    }
    @media (max-width: $bp-tablet) {
      background-image: url(../assets/images/top_slider/mobile/01_top-slider_dtm_mobile.jpg);
    }
}


