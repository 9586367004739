@import "colors";
/* Add animation (Chrome, Safari, Opera) */
@-webkit-keyframes fadeMove {
  from {top:-100px;opacity: 0;}
  to {top:0px;opacity:1;}
}

/* Add animation (Standard syntax) */
@keyframes fadeMove {
  from {top:-100px;opacity: 0;}
  to {top:0px;opacity:1;}
}

.mobile-break {
  display: none;
}


/* The modal's background */
.modal {
  display: none;
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  /* overflow: auto; */
  
  /* padding: 4.7% 8.5%; */

  .logo {
    display: block;
    margin: auto;
    &:hover {
      background-image: url(../assets/images/Logo_CHRW_teal.svg);
    }
  }

     
}


.modal:not(.modal--start) {
  background-color: black;
}

/* Display the modal when targeted */
.modal:target, 
.modal--start,
.modal--opened {
  display: table;
  position: absolute;
  z-index: 999999;
}



.modal.modal--white {
  background-color: white;
}

.modal--white .modal-text,
.modal--white .modal-header h2, 
.modal--white .modal-header .closebtn,
.modal--white footer a {
  color: black;
}



.modal.modal--teal {
  background-color: $teal;

  .logo {
    &:hover {
      background-image: url(../assets/images/Logo_CHRW_lightgrey.svg);
    }
  }
}

.modal--teal .modal-text,
.modal--teal .modal-header h2 {
  color: #fff;
}

.modal--teal .modal-header .closebtn,
.modal--teal footer a {
  color: #fff;

  &:hover {
    color: $lightgrey;
  }
}




/* The modal's content */
.modal-content {
  position: relative;
  padding: 0;
  outline: 0;
  text-align: left;
  width: 100%;
  animation-name: fadeMove;
  animation-duration: 0.5s;
  color: white;
  height: 100%;
  overflow: auto;
  /* padding: 4.7% 8.5%; equals 90px 164px @ 1920 */
  // padding: 90px 8.5%; /* equals 90px 164px @ 1920 */
  padding: 40px 4.5%;
}

.modal--start {
  background-image: url(../assets/images/video-overlay-bg.jpg);
  // background-image: url(https://cw.studio/assets/images/video-poster-mobile.gif);
  background-color: white;
  background-size: cover;
  height: 100vh;
  overflow: hidden;

  @media screen and (min-width: 680px) {
    background-image: url(../assets/images/video-poster.jpg);
  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(0px);
    overflow: hidden;
    
  }

  .modal-text {
    color: black;
    font-weight: 900;
    font-size: 90px;
    letter-spacing: 0.9px;
    line-height: 95px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 1400px) {
      // font-size: 8.3vw; // equals 90px @ 1080px
      // line-height: calc(9vw + 10px);
      font-size: 6.4vw; //equals 90px @ 1400px
      line-height: 6.8vw;
    }
    @media screen and (max-width: 680px) {
      font-size: 45px;
      line-height: 65px;
    }

    @media screen and (max-width: 360px) {
      font-size: 38px;
      line-height: 55px;
    }

     @media screen and (min-width: 1400px) and (max-height: 750px) {
      line-height: 1.2em;
      font-size: 50px;
    }
    
    p.sound-on {
      font-size: 35px;
      margin-bottom: 0;
      margin-top: 40px;
      letter-spacing: 0.7px;
      margin-left: 50%;
      transform: translateX(-50%);
      animation: flashYellow 4s infinite ease-in-out;
      animation-delay: 3s;

      @media screen and (max-width: 1400px) { 
        margin-top: -30px;
      }

      @media screen and (max-width: 680px) {
        margin-top: -45px;
        font-size: 25px;
      }

      
    }
  }

  .closebtn--start {
    background-image: url(../assets/images/Go-Button.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 0;
    width: 170px;
    height: 198px;
    margin: 55px auto 0 auto;
    float: none;
    color: black;
    font-size: 58px;
    cursor: pointer;
    // &:hover {
    // }
    &:hover {
      background-image: none;
      img {
        visibility: visible;
      }
    }

    img {
      visibility: hidden;
    }
    @media screen and (max-width:1400px) {
      width: 12vw; // = 170px @ 1400px vw
    }
    @media screen and (max-width:1400px) {
      width: 110px;
    }
  }
}

/* The button used to close the modal */
.modal-header .closebtn {
  text-decoration: none;
  float: right;
  font-size: 35px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: $teal;
  }
}
/* 
.closebtn:hover,
.closebtn:focus {
  text-decoration: none;
  cursor: pointer;
} */

/* XXX.container {
  padding: 2px 16px;
} */

.modal-header {
  /* background-color: #5cb85c; */
  font-size: 25px;
  color: white;
}

.modal-header h2, .modal-header .closebtn {
  font-size: 90px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 0;
}

@media screen and (max-width: 1540px) { 
  .modal-header h2, 
  .modal-header .closebtn  {
    font-size: 5.85vw; /* equals fontsize 90px at 1540px viewport width */
  }
}

@media screen and (max-width: 860px) {  
  .mobile-break {
    display: inline;
  }

  .modal-content { 
    padding-top: 60px;
    padding-bottom: 60px;
  }


  .modal-header h2, .modal-header .closebtn  {
    font-size: 8vw;
  }
}

/* @media screen and (max-width: 460px) { 
  .modal-header h2, 
  .modal-header .closebtn  {
    margin-top: 40px;
  }
} */


.modal-text {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.4px;
}

.modal-text a {
  color: inherit;
}

.modal-header h2 {
  margin-bottom: 85px;
}

.modal-text h3 {
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 40px;
}

.modal-footer {
  text-align: center;
  background-color: transparent;
}

@media screen and (max-width: 1540px) {
  .modal-text h3 { 
    font-size: 2.6vw;
  }
}


@media screen and (max-width: 860px) {
  .modal-text h3 { 
    font-size: 26.5px
  }
}


@media screen and (max-width: 460px) { 
  .modal-text {
    font-size: 16px;
    line-height: 25px;
    word-break: break-word;
  }

  .modal-text h3 { 
    font-size: 16px;
    text-align: left;
  }
}

@keyframes flashYellow {
  20%, 100% {
    // opacity: 0;
    color: black;
    // transform: translate(200px, 0);
  }
  0% {
    // opacity: 0;
    color: black;

  }
  15% {
    // opacity: 1;
    color: teal;
  }
}