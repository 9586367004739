@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-zoom-out {
  0% {
    opacity: 0;
    transform: scale(1.3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-out-zoom-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  99% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 0;
  }
}