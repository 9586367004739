footer {
  // background-color: white;
  // color: black;
  // padding: 80px 50px 35px 50px;
  text-align: center;
  background-image: none;
  background-size: cover;
  padding-bottom: 20px;
}

@media /* CSS fpr high resolution display  */
  screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-resolution: 192dpi),
  screen and (min-resolution: 2dppx) {


}

footer a, footer a {
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  font-weight: 900;
}


footer .links a {
  margin-bottom: 55px;
  display: table;
  margin: 0 auto;
  font-size: 180px;
  letter-spacing: 1.8px;
  line-height: 1em;

  transition: color 0.3s ease-in-out;
}

// .links a:hover,
// .footer-nav a:hover {
//   color: $teal;
// }

// @media screen and (max-width: $bp-desktop) {
//   footer .links a {
//     font-size: 17.65vw; /* equals fontsize 180px at 1024 viewport width */
//     line-height: calc(0.9em + 20px); /* equals lh 1em at 1024 */
//   }
// }

// @media screen and (max-width: 420px) {
//   footer .links a {
//     font-size: 15.65vw; 
//   }
// }

footer a:after {
  content: '\A';
  white-space:pre;
}


.logo--bottom {
  display: inline-block; 
  /*height: auto;*/
  @media (max-width: $bp-tablet) {
    width: 65px;
  }
}
.logo--black {
  background-image: url(../assets/images/Logo_CHRW_black.svg);
}

// nav.footer-nav {
//   text-transform: uppercase;
//   color: white;
// }

nav.footer-nav { 
  margin-top: 30px;
}

.footer--main-page {
  @media (max-width: $bp-tablet) { 
    padding-bottom: 0;
  }

  nav.footer-nav {
    @media (max-width: $bp-tablet) { 
      margin-top: -25px;
    }
  }
}

nav.footer-nav ul {
  padding: 0;
}
nav.footer-nav li {
  list-style: none;
  cursor: pointer;
}

nav.footer-nav li:first-of-type {
  margin-bottom: 15px;
  @media (max-width: $bp-tablet) { 
    margin-bottom: 10px;
  }
}

nav.footer-nav a {
  font-size: 20px;
  letter-spacing: 0.3px;
  @media (max-width: $bp-tablet) {
    font-size: 12px;
  }
}

nav.footer-nav li:first-of-type a {
  /* make touchable area bigger for mobile */
  padding-top: 20px;
}

nav.footer-nav li:nth-of-type(2) a {
  /* make touchable area bigger for mobile */
  padding-bottom: 20px;
}

@media screen and (max-width: 650px) {
  nav.footer-nav a {
    display: inline-block;
    width: 100%;
    
  }
}