.section--performance {
  padding-bottom: 75px;
  .right-flexed-wrapper .headline {
    margin-bottom: 120px;
    margin-bottom: pxToVw(120, 1920);
    @media (min-width: 1921px) {
      margin-bottom: 120px
    }
  }

  .headline--expertise {
    margin-top: 115px;
    @media (max-width:$bp-tablet) {  
      margin-bottom: 45px;
    }
  }

  .parallax-img-wrapper {
    position: absolute;
    left: -270px;
    left: pxToVw(-170, $outer-width-lg);
    top: 60px;

    @media (min-width: $bp-large) {
      left: -170px;
    }
  
    @media (max-width: $bp-desktop_lg) {
      // top: 310px;
    }
  
    @media (max-width:$bp-desktop) {
      right: 55%;
      left: auto;
      // top: 110px;
    }
    @media (max-width:$bp-tablet) {
      // transform: translateY(0%);
      right: auto;
      left: 28%;
      top: 60px;
    }
  
     @media (max-width: 680px) {
  
     }
     @media (max-width: 550px) {
      right: 250px;
      left: auto;
     }
  
  }


  .bg-image--performance {
    max-width: 920px;
    width: pxToVw(920, $outer-width-lg);
    transform: rotate(-15deg);


    @media (max-width:$bp-tablet) {
      // transform: translateY(0%);
      width: 435px;
    }
 
  
  }
  
  
}

.text--performance {
  p { 
    max-width: 960px;
    width: pxToVw(960, $outer-width-lg);
    margin-right: 30px;
    margin-right: pxToVw(30, $outer-width-lg);
    @media (max-width: $bp-desktop) {
      max-width: none;
      width: 100%;
    }

    &.bg-margin-mobile {
      @media (max-width:$bp-tablet) { 
        margin-top: 310px;
      }
    }
  }
}

// .bg-image--atomium--rotated {
//   display: none;
// }



